<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="is_auth('weapphome.keyword.issave')" icon="el-icon-plus"
                                   size="medium" type="primary" @click="add_page">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    v-loading="loading"
                    :data="tableData"
                    border
                    size="medium"
                    style="width: 100%">
                <el-table-column prop="sort" label="排序"></el-table-column>
                <el-table-column prop="keyword" label="关键字"></el-table-column>
                <el-table-column prop="sum" label="搜索次数"></el-table-column>
                <el-table-column prop="create_time" label="添加时间" width="180"></el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="150">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('weapphome.keyword.isdel')"
                                @click="operation_tip (scope.row.id,scope.row.keyword,'isdel')"
                                type="danger" size="mini">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--编辑-->
            <el-dialog v-loading="loading"
                       v-if="is_auth('weapphome.keyword.issave')"
                       title="编辑关键字"
                       :visible.sync="editPage"
                       width="50%"
                       :close-on-press-escape="false"
                       :close-on-click-modal="false"
            >
                <el-row>
                    <el-form size="medium" ref="form" :label-width="this.env.label_width">
                        <el-col :span="24">
                            <el-form-item label="排序">
                                <el-input v-model="info.sort" type="number" :min="1" :max="5"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="关键字">
                                <el-input v-model="info.keyword" maxlength="10"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button size="medium" type="primary" @click="save">添 加</el-button>
                    <el-button size="medium" @click="editPage = false">取 消</el-button>
                </el-row>
            </el-dialog>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '默认搜索关键字',
            issearch: false,
            loading: true,      // 加载状态
            count: 0,        // 数据总条数
            tableData: [],// 列表内容
            page: 1,            // 当前页数
            info: {}, // 编辑数据
            editPage: false,
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "weapphome.keyword.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.$message.error(json.message);
                }
            })
        },
        // 操作提示
        operation_tip(id = '', keyword = '', operation = '') {

            let tip = ''
            let options = {}
            // 删除
            if (operation === 'isdel') {
                tip = '删除【' + keyword + '】？'
                options = {type: 'error'}
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(id, operation)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(id = '', operation = '') {
            let postdata = {
                api_name: "weapphome.keyword." + operation,
                token: this.Tool.get_l_cache('token'),
                id,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 添加页面显示
        add_page() {
            this.tag_uuid = ''
            this.info = {
                keyword: '',
                sort: '',
            }
            this.editPage = true    // 显示页面
        },
        // 保存
        save() {
            let postdata = {
                api_name: 'weapphome.keyword.issave',
                token: this.Tool.get_l_cache('token'),
                sort: this.info.sort,
                keyword: this.info.keyword,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.editPage = false
                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tag_colour {
    height: 28px;
    /*background: #2c3e50;*/
    border: 1px solid;
    text-align: center;
    line-height: 28px;
}

.tag_colour-tag {
    border: 0px;
}
</style>
